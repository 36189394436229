<!-- 经销商步数 -->
<template>
  <div class="DealerSteps">
    <el-table v-loading="listLoading" :data="list" border fit highlight-current-row style="width: 100%;">
      <!--   经销商编号   -->
      <el-table-column :label="$t('miMember.memberNo')" prop="memberNo" align="center" />
      <!--   步数   -->
      <el-table-column :label="$t('Step_number')" prop="stepNum" align="center" />
      <!--   创建人   -->
      <el-table-column :label="$t('icAnnounce.creatorCode')" prop="createdBy" align="center" />
      <!--   创建时间   -->
      <el-table-column :label="$t('Common.createTime')" prop="createTime" align="center" />
      <!--     日期 -->
      <el-table-column :label="$t('title.date')" prop="wDay" align="center" />
    </el-table>
    <!--    分页-->
    <pagination v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
    <!-- <el-button style="float: right; margin-bottom: 10px" type="warning" @click="continueBtn" plain>{{$t('btn.continue')}}</el-button> -->
  </div>
</template>
<script>
  import { pageByDTO } from "@/api/mi/memberStepnum.js"
  import Pagination from '@/components/Pagination'
  export default {
    name: 'DealerSteps',
    components: { Pagination },
    data () {
      return {
        listLoading: false,
        list: [],
        listQuery: {
          page: 1,
          limit: 10
        },
        total: 0
      }
    },
    created () {
      this.getList()
    },
    methods: {
      getList () {
        this.listLoading = true
        pageByDTO({ ...this.listQuery }).then((res) => {
          this.list = res.data.records
          this.total = +res.data.total
          this.listLoading = false
        }).catch(() => {
          this.listLoading = false
        })
      },
      continueBtn() {
        this.getList()
      }
    }
  }
</script>

 <style style lang = "scss" scoped >
</style>
