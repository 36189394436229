import request from '@/utils/request'

// 经销商步数管理（分页）
export function pageByDTO(query) {
    return request({
        url: '/memberStepnum/pageByDTO',
        method: 'get',
        params: query
    })
}

// 经销商步数管理（集合）
export function listByDTO(query) {
    return request({
        url: '/memberStepnum/listByDTO',
        method: 'get',
        params: query
    })
}

// 经销商步数新增
export function addMemberStepnum(data) {
    return request({
        url: '/memberStepnum/save',
        method: 'post',
        data
    })
}

// 经销商步数新增(多条)
export function addMemberStepnums(data) {
    return request({
        url: '/memberStepnum/saves',
        method: 'post',
        data
    })
}
